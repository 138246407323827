import React, { useEffect, useState } from 'react';
import { injectFunction } from './script.js'

const CHAT_WIDGET_SCRIPT_TAG_ID = '__chatWidgetScriptTag' 
const CHAT_WIDGET_DIV_TAG_ID = '__chatWidgetDIVTag' 

function MyComponent() {
  const [scriptInfo, setScriptInfo] = useState({
    appId: '',
    appKey: ''
  })
  const [errorMessage, setErrorMessage] = useState('')

  const [chatScript, setChatScript] = useState(null)
  const [loader, setLoader] = useState(false)


  // const attachScript = () => {
  //   const script = document.createElement('script');
  //   script.id = CHAT_WIDGET_SCRIPT_TAG_ID;
  //   script.innerHTML = chatScript;
  //   document.head.appendChild(script);
  // }

  useEffect(() => {
    const recievePostMessage = (e) => {
      console.log('Action POST MESSAGE INvoked : ', e.data)
      const type = e.data.action
      switch (type) {
        case "closeChat": 
          handleClearChatScript()
          break
        default: 
          console.log('default : ', type)
      }
    }
    window.addEventListener('message', recievePostMessage)
  }, [])

  useEffect(() => {
    if (chatScript) {
      if(!document.getElementById(CHAT_WIDGET_SCRIPT_TAG_ID)) {
        console.log(chatScript)
        const script = document.createElement('script');
        script.id = CHAT_WIDGET_SCRIPT_TAG_ID;
        script.innerHTML = chatScript;
        document.head.appendChild(script);
        // attachScript()
      }
    } 
  }, [chatScript])




  const handleClick = () => {
    if (loader) return

    setTimeout(() => setLoader(false), 1000)
    console.log('on click : ', window._ccw)
    setErrorMessage('')
    window._moduleCCW.initChatApp()
    // Create script
   

    /*script.onload = function() {
      // Call the function here
      ChatAdaptor.init(
        {  
        appInfo:{
            appId: 'b5aff699-fc04-43b3-bf14-a99af73c6f75',
            appKey: '93b7069d8ec57c2ea4f62c35ea25692fba422c639237ecb06d6dc354258f5da3',
            device: 'web-native'
          }
        }
      )
  };
  */
 /*
  window._moduleCCW.init().then((res) => {
    console.log('res :', res)
     setTimeout(() => setLoader(false), 1000)
  }).catch((err) => {
    console.log('err :', err)
    setLoader(false)
    if (err.message) {
      setErrorMessage(err.message)
      setTimeout(() => {
        setErrorMessage('')
      }, 3000)
    }
   
  })*/
    // console.log(window._moduleCCW)
  }

  const onScriptInfoSubmit = () => {
    console.log(scriptInfo)
    setChatScript(injectFunction(scriptInfo.appId, scriptInfo.appKey))
  }

  const onScriptInfoDataChange = (value, key) => {
    setScriptInfo(state => ({ ...state, [key]: value}))
  }

  const handleClearChatScript = () => {
    const chatScriptTagEle = document.getElementById(CHAT_WIDGET_SCRIPT_TAG_ID)
    const chatScriptTagEleCCW = document.getElementById('_ccw')
    const chatDivTagEle = document.getElementById(CHAT_WIDGET_DIV_TAG_ID)
    chatScriptTagEle && chatScriptTagEle.parentNode?.removeChild(chatScriptTagEle)
    chatDivTagEle && chatDivTagEle.parentNode?.removeChild(chatDivTagEle)
    chatScriptTagEleCCW && chatScriptTagEleCCW.parentNode?.removeChild(chatScriptTagEleCCW)
  }

  /**
   * Render script info form
   * @returns 
   */
  const _renderScriptInfoForm = () => {
    return (
      <div className='script-info-form'>
        <p>App ID</p>
        <input 
          placeholder='Enter App ID' 
          onChange={(e) => onScriptInfoDataChange(e.target.value, 'appId')} 
          value={scriptInfo.appId}/>
        <p>App Key</p>
        <input 
          placeholder='Enter App Key'
          onChange={(e) => onScriptInfoDataChange(e.target.value, 'appKey')} 
          value={scriptInfo.appKey}/>
        <button onClick={onScriptInfoSubmit}>Submit</button>
      </div>
    )
  }

  /**
   * Render Chat Start Section
   * @returns 
   */
  const _renderChatStartSection = () => {
    return <div className='chatstart-wrap'>
    <button onClick={handleClick} className={`${loader && 'disable-btn'} chat-start-btn`} disabled={loader}> {!loader ? 'Chat with us' : 'Loading Chat ...'} </button>
    {errorMessage && <p className='error-msg'>{errorMessage}</p>}
    <p 
      onClick={() => { 
        handleClearChatScript()
        setChatScript(null)}
      } 
      className='tryagain-btn'>
        &larr; Back
    </p>
  </div>
  }

  return (
    <div className='webview'>
      {chatScript ? 
          _renderChatStartSection()
        : _renderScriptInfoForm()
      }
    </div>
  );
}

export default MyComponent;
