export  const injectFunction = (appId, appKey) => `
(function(d, s, o, f, js, fjs) {
  const origin = 'https://chat-widget.contacto.com';
  js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
  js.id = o;
  js.src = origin + f;
  js.async = 1;
  fjs.parentNode.insertBefore(js, fjs);
  js.onload = (e) => (_moduleCCW.init(origin))
}(document, 'script', '_ccw', '/assets/js/widget.js'));
window.__C_CHAT_CONFIG = {
appInfo:{
  appId: '${appId}',
  appKey: '${appKey}',
  region: 'us-east-1',
  device: 'web',
  appType: 'pwa'
}
}
`;
