import './App.css';
import MyComponent from './utils/webview.jsx'

function App() {
  return (
    <div className="App">
        <MyComponent/>
    </div>
  );
}

export default App;
